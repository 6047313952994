
import { Vue, Component } from "vue-property-decorator";
import Spinner from "../store/modules/spinnerModule";

@Component
export default class SpinnerComponent extends Vue {
  get spinnerState(): boolean {
    return Spinner.showLoader;
  }
}
