import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "../";

@Module({
  namespaced: true,
  name: "Spinner",
  store,
  dynamic: true,
})
class SpinnerModule extends VuexModule {
  public showLoader = false;

  @Action
  public Show() {
    this.setState(true);
    return;
  }
  @Action
  public Hide() {
    this.setState(false);
    return;
  }

  @Mutation
  private setState(value: boolean) {
    this.showLoader = value;
  }
}
export default getModule(SpinnerModule);
